@font-face {
  font-family: Montserrat-Regular;
  src: url("./assets/font/static/Montserrat-Regular.ttf");
}
@font-face {
  font-family: Montserrat-Bold;
  src: url("./assets/font/static/Montserrat-Bold.ttf");
}
@font-face {
  font-family: Montserrat-Bold;
  src: url("./assets/font/static/Montserrat-Bold.ttf");
}
@font-face {
  font-family: Montserrat-Italic;
  src: url("./assets/font/static/Montserrat-Italic.ttf");
}
@font-face {
  font-family: Montserrat-Light;
  src: url("./assets/font/static/Montserrat-Light.ttf");
}
@font-face {
  font-family: Montserrat-Medium;
  src: url("./assets/font/static/Montserrat-Medium.ttf");
}
