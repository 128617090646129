h4 {
  font-size: smaller;
}
#kick-table-container {
  max-height: 400px; /* You can adjust this height */
  overflow-y: auto; /* Allows vertical scrolling */
  width: 100%;
}

.kick-table {
  width: 100%;
}

.details {
  padding: 20px 20px 20px 20px;
  font-family: Montserrat-Regular;
  font-size: smaller;
  overflow-x: scroll;
  #paid {
    color: green;
  }
  #not-paid {
    color: red;
  }
}
