h1 {
  font-size: 40px;
}
#form-body {
  font-family: Montserrat-Light;
}
@media screen and (max-width: 900px) {
  h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 700px) {
  h1 {
    font-size: 28px;
  }
}
@media screen and (max-width: 650px) {
  .hide {
    display: none;
  }
  h1 {
    font-size: 24px;
  }
}
@media screen and (max-width: 500px) {
  .hide {
    display: none;
  }
  h1 {
    font-size: 22px;
  }
}
@media screen and (max-width: 450px) {
  .hide {
    display: none;
  }
  h1 {
    font-size: 18px;
  }
}
@media screen and (max-width: 375px) {
  .hide {
    display: none;
  }
  h1 {
    font-size: 16px;
  }
  label {
    font-size: 12px;
  }
}
