#summary {
  padding: 50px 50px 50px 50px;
  background-color: #333232;
  color: white;
  border-radius: 50px;
}

#rPaid {
  margin-left: 10px;
}

#issue {
  background-color: yellow;
  width: fit-content;
  color: black;
}

#properties {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 50px;
}
.pac-container {
  z-index: 9999 !important;
}

#card_title {
  font-size: 15px;
  font-family: Montserrat-Regular;
}
