.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
h1,
h4 {
  font-family: Montserrat-Regular;
}
#buttons {
  font-family: Montserrat-Regular;
}
.modal-font {
  font-family: Montserrat-Regular;
}
