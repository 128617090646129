#summary {
  padding: 50px 50px 50px 50px;
  background-color: #333232;
  color: white;
  border-radius: 50px;
}

#rPaid {
  margin-left: 10px;
}

#issue {
  background-color: yellow;
  width: fit-content;
  color: black;
}
p {
  font-size: smaller;
}
#text-highlight {
  color: teal;
}
