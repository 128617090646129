.footer {
  background-color: #191919;
  p {
    margin: auto;
    color: white;
    opacity: 0.5;
  }
}
#underline {
  width: 50%;
  border: 2px solid #00cdac;
  margin-left: 25%;
  opacity: 0.75;
}
